/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <a
                    href="https://intercsa.com/"
                    target="_blank"
                    className="mr-1"
                  >
                    ICSA
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/international-center-for-strategic-alliances/"
                    target="_blank"
                    className="mr-1"
                  >
                    <i className="fa fa-linkedin" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/Intercsa"
                    target="_blank"
                    className="mr-1"
                  >
                    <i className="fa fa-twitter" />
                  </a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © 2020, Elahriex Concepts. All rights reserved.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
