import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    UncontrolledTooltip,
} from "reactstrap";
// core components

function Header({

}) {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [bodyClick, setBodyClick] = React.useState(false);
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [scrolled, setScrolled] = React.useState(false);

    React.useEffect(() => {
        // let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        // headroom.init();
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 499 ||
                document.body.scrollTop > 499
            ) {
                setNavbarColor("");
                setScrolled(true);
            } else if (
                document.documentElement.scrollTop < 500 ||
                document.body.scrollTop < 500
            ) {
                setNavbarColor("navbar-transparent");
                setScrolled(false);
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    return (
        <>
            {bodyClick ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setBodyClick(false);
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar
                className={classnames("fixed-top", navbarColor)}
                expand="lg"
                id="navbar-main"
            >
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
                            <img
                                src={scrolled ? "images/elahriex-concepts-logo-icon.png" : "images/elahriex-concepts-logo.png"}
                                alt="Elahriex Concepts"
                                width={scrolled ? "50" : "150"}
                            />
                        </NavbarBrand>
                        <button
                            className="navbar-toggler"
                            id="navigation"
                            type="button"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setBodyClick(true);
                                setCollapseOpen(true);
                            }}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <Collapse navbar isOpen={collapseOpen}>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Button
                                    className="btn-neutral"
                                    color={scrolled ? "default" : "link"}
                                    href="#"
                                // onClick={(e) => e.preventDefault()}
                                >
                                    Home
                                </Button>
                                <Button
                                    className="btn-neutral"
                                    color={scrolled ? "default" : "link"}
                                    href="#about"
                                // onClick={(e) => e.preventDefault()}
                                >
                                    About
                                </Button>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle className="mr-2" color="default" caret nav>
                                    Services
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-danger" right>
                                    <DropdownItem to="/services" tag={Link}>
                                        Conferences
                                    </DropdownItem>
                                    <DropdownItem to="/services" tag={Link}>
                                        Webinars
                                    </DropdownItem>
                                    <DropdownItem to="/services" tag={Link}>
                                        Managed Events
                                    </DropdownItem>
                                    <DropdownItem to="/services" tag={Link}>
                                        Trainings
                                    </DropdownItem>
                                    <DropdownItem to="/services" tag={Link}>
                                        Round Tables
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle className="mr-2" color="default" caret nav>
                                    Events
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-danger" right>
                                    <DropdownItem href="https://globalemergetechsummit.com/" as={Link}>
                                        GES2022
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <Button
                                    className="btn-round"
                                    color="danger"
                                    href="#contact-us"
                                >
                                    <i className="nc-icon nc-email-85" /> Contact Us
                                </Button>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default Header;
