import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="fadeInAnim">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="8">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img"
                    src={
                      require("assets/img/our-team-bg.jpg")
                        .default
                    }
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    src={
                      require("assets/img/our-team-01.jpg")
                        .default
                    }
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    src={
                      require("assets/img/our-team-02.jpg")
                        .default
                    }
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">Our Team</h3>
                  <h6 className="category"></h6>
                  <h5 className="description font-weight-normal">
                    At Elahriex, our team has highly trained professionals who are reliable, efficient and dedicated to their tasks. The laser-sharp focus of these experts ensures the complete success of the projects undertaken. Our company has many branches globally in over 13 countries and three continents. We aim to offer single-point access to communication for businesses to make the entire process convenient and fruitful.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;
