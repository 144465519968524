import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Input,
} from "reactstrap";

// core components
import Header from "components/header.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
// sections for this page
import SectionContent from "./presentation-sections/SectionContent.js";
import SectionResponsive from "./presentation-sections/SectionResponsive.js";
import SectionButtons from "./index-sections/SectionButtons.js";
import SectionNavigation from "./index-sections/SectionNavigation.js";
import SectionNavbars from "./index-sections/SectionNavbars.js";
import SectionPreFooterAreas from "./index-sections/SectionPreFooterAreas.js";
import SectionFooterAreas from "./index-sections/SectionFooterAreas.js";
import SectionDescriptionAreas from "./index-sections/SectionDescriptionAreas.js";
import SectionTypography from "./index-sections/SectionTypography.js";
import SectionNotification from "./index-sections/SectionNotification.js";
import SectionTables from "./index-sections/SectionTables.js";
import SectionComments from "./index-sections/SectionComments.js";
import SectionCommentsAreaSmall from "./index-sections/SectionCommentsAreaSmall.js";
import SectionJavaScript from "./index-sections/SectionJavaScript.js";
import SectionCards from "./index-sections/SectionCards.js";

import apiService from './../services/app.service';

function Index() {
    const [formValues, setFormValues] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
    });

    document.documentElement.classList.remove("nav-open");

    // function that is being called on scroll of the page
    const checkScroll = () => {
        // it takes all the elements that have the .add-animation class on them
        const componentPosition = document.getElementsByClassName("add-animation");
        const scrollPosition = window.pageYOffset;
        for (var i = 0; i < componentPosition.length; i++) {
            var rec =
                componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
            // when the element with the .add-animation is in the scroll view,
            // the .animated class gets added to it, so it creates a nice fade in animation
            if (scrollPosition + window.innerHeight >= rec) {
                componentPosition[i].classList.add("animated");
                // when the element with the .add-animation is not in the scroll view,
                // the .animated class gets removed from it, so it creates a nice fade out animation
            } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
                componentPosition[i].classList.remove("animated");
            }
        }
    };

    React.useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("presentation-page");
        document.body.classList.add("contact-page");

        window.addEventListener("scroll", checkScroll);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("index-page");
            window.removeEventListener("scroll", checkScroll);
        };
    }, []);

    // const scrollToBottom = () => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }

    const updateFormValue = (key, value) => {
        setFormValues(_ => {
            let _ps = _;
            _ps[key] = value;
            return { ..._ps };
        });
    }

    const submitMessage = async () => {
        // console.log("formValues", formValues);
        // const res = await apiService.submitForm(formValues.firstName + " " + formValues.lastName, formValues.email, formValues.subject, formValues.message);
        setFormValues({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: '',
        });
    }

    return (
        <>
            <Header />
            <IndexHeader />
            <div className="main" >
                <div className="wrapper">
                    <div className="section text-center landing-section" id="about">
                        <Container>
                            <Row>
                                <Col className="ml-auto mr-auto" md="10">
                                    <h2 className="title">About Us</h2>
                                    <h5>
                                        Elahriex is a team of like-minded licensed professionals and developers where our primary goal is to provide a platform for leaders to innovate and utilize case scenarios by employing cutting-edge research and neoteric practices for businesses across the globe.
                                    </h5>
                                    <h5>
                                        At Elahriex, we emphasize well-researched data because of its invaluable market significance. To give you a competitive edge in the market, we offer exclusive conferences and training sessions along with relevant case data, white paper and curated infographics. These insights with extensive industry knowledge will allow your business and professionals to raise ranks in the industry.
                                    </h5>
                                    <br />
                                    {/* <Button
                                        className="btn-fill btn-round"
                                        color="danger"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        See Details
                                    </Button> */}
                                </Col>
                            </Row>
                            <br />
                            <br />
                            <Row>
                                <Col md="6">
                                    <div className="info">
                                        <div className="icon icon-danger">
                                            <i className="nc-icon nc-bulb-63" />
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title"></h4>
                                            <p className="font-weight-bold">
                                                We work with leading event planners to create innovative solutions best designed to fit your needs. All services we offer are accredited worldwide and held to the highest degree of international standards. We firmly believe in the scope of exclusive events to create a competitive edge in the market.
                                            </p>
                                            <Button
                                                className="btn-link"
                                                color="danger"
                                                href="#contact"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Know More
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="info">
                                        <div className="icon icon-danger">
                                            <i className="nc-icon nc-sun-fog-29" />
                                        </div>
                                        <div className="description">
                                            <h4 className="info-title"></h4>
                                            <p className="font-weight-bold">
                                                The services we offer range from tackling the logistics of the entire event or selected assistance with a few fundamental aspects to make your event a resounding success. Within the operations of our full-service event planning business, we provide technical expertise across various types of conferences.
                                            </p>
                                            <Button
                                                className="btn-link"
                                                color="danger"
                                                href="#contact"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                Know More
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <SectionContent />
                <SectionResponsive />
                <div className="section section-gray" id={'contact-us'}>
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h2 className="title">Get in touch with us</h2>
                                <p>
                                    Collaboratively administrate empowered markets via
                                    plug-and-play networks.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="6">
                                <h3 className="title">
                                    <small>Drop us a note</small>
                                </h3>
                                <Form className="contact">
                                    <Row>
                                        <Col md="6">
                                            <Input placeholder="First Name" type="text" value={formValues.firstName} onChange={(e) => updateFormValue('firstName', e.target.value)} />
                                        </Col>
                                        <Col md="6">
                                            <Input placeholder="Last Name" type="text" value={formValues.lastName} onChange={(e) => updateFormValue('lastName', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <Input placeholder="Email" type="text" value={formValues.email} onChange={(e) => updateFormValue('email', e.target.value)} />
                                        </Col>
                                        <Col md="6">
                                            <Input placeholder="Subject" type="text" value={formValues.subject} onChange={(e) => updateFormValue('subject', e.target.value)} />
                                        </Col>
                                    </Row>
                                    <Input placeholder="Message" rows="7" type="textarea" value={formValues.message} onChange={(e) => updateFormValue('message', e.target.value)} />
                                    <Row>
                                        <Col className="ml-auto mr-auto" md="6">
                                            <Button block className="btn-round" color="danger" onClick={() => submitMessage()}>
                                                Send
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <FooterBlack />
        </>
    );
}

export default Index;
