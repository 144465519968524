import axios from "axios";
import fs from "fs";
// import CONSTANTS from './../data/constants.data';

// const API_URL = CONSTANTS.API_URL;

export default {
    submitForm: async (
        fullName = null,
        email = null,
        subject = null,
        message = null,
    ) => {
        try {
            // var bodyFormData = new FormData();
            var data = {
                fullName, email, subject, message
            };
            // console.log(data);

            // for (const [key, value] of Object.entries(data)) {
            //     if (value !== null) {
            //         bodyFormData.append(key, value);
            //     }
            // }

            var readMe = fs.readFileSync('logs.txt', 'utf-8');
            var jsonData = JSON.parse(readMe) || [];
            jsonData.push(data);
            fs.writeFile("logs.txt", JSON.stringify(jsonData), (err) => {
                if (err) console.log(err);
            });


            // return await axios.post(API_URL + "register_api.php", bodyFormData, {
            //     headers: { "Content-Type": "multipart/form-data" },
            // }).catch(e => {
            //     throw e.response.data.message;
            // });
        } catch (e) {
            console.log(e);
            throw e;
        }
    },
}