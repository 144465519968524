import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionResponsive() {
  return (
    <>
      <div className="section section-responsive section-gold">
        <Container fluid>
          <Row>
            <Col md="7">
              <div className="phone-container">
                <img
                  alt="..."
                  src={
                    require("assets/img/presentation-page/responsive.png")
                      .default
                  }
                />
              </div>
            </Col>
            <Col lg="4">
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-trophy" />
                </div>
                <div className="description">
                  <h4 className="info-title font-weight-normal">Our Vision</h4>
                  <p className="font-weight-bold">
                    At Elahriex, we aim to empower our clients with industry insights and technical expertise to better help with innovation and creativity to ultimately help them expand their business and achieve their goals on a global scale.
                  </p>
                  <p className="font-weight-bold">
                    We strive for target achievement and work towards positive feedback to our client's ventures by equipping them with the necessary tools and resources to ensure their holistic growth.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-spaceship" />
                </div>
                <div className="description">
                  <h4 className="info-title font-weight-normal">Our Mission</h4>
                  <p className="font-weight-bold">
                    To equip our clients with industry-grade insights and data necessary to create and sustain a competitive edge in the market.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionResponsive;
